.btn-very-lg {
    padding: 15px 50px;
    margin-top:5px;
    font-size: 36px;
    line-height: 1.3333333;
    border-radius: 6px;
}

.btn-erp {
    margin-top: 20px;
    padding: 0.3vw 1vw;
    font-size: calc(11px + 0.3vw);
    line-height: 1.3333333;
    border-radius: 6px;
}



.btn-secondary {
    background-color: darkgray !important;
    color: #fff !important;
    border-color: gray;
}
